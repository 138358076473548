var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Navbar', {
    attrs: {
      "nav-light": true
    }
  }), _c('section', {
    staticClass: "bg-half bg-primary d-table w-100"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-lg-12 text-center"
  }, [_c('div', {
    staticClass: "page-next-level"
  }, [_c('h4', {
    staticClass: "title text-white",
    staticStyle: {
      "text-transform": "capitalize"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("trust.lists")) + " ")])])])])])]), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container overflow-hidden"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('b-card', {
    staticClass: "mb-1",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', {
    staticClass: "p-1",
    attrs: {
      "header-tag": "header",
      "role": "tab"
    }
  }, [_c('b-button', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.accordion-1",
      modifiers: {
        "accordion-1": true
      }
    }],
    attrs: {
      "block": "",
      "variant": "info"
    }
  }, [_vm._v(_vm._s(_vm.$t("search2")))])], 1), _c('b-collapse', {
    attrs: {
      "id": "accordion-1",
      "visible": _vm.isSearch,
      "accordion": "my-accordion",
      "role": "tabpanel"
    }
  }, [_c('b-card-body', [_c('div', {
    staticClass: "row"
  }, [_vm.userRole == 'Staff' ? _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("org-type")))]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.organization_type,
      expression: "form.organization_type"
    }],
    staticClass: "form-control",
    attrs: {
      "required": "",
      "id": "organization_type"
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.form, "organization_type", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }, function ($event) {
        return _vm.onChange($event);
      }]
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("all")))]), _c('option', {
    attrs: {
      "value": "AWARIS"
    }
  }, [_vm._v("AWARIS")]), _c('option', {
    attrs: {
      "value": "Koperasi"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("cooperative")) + " ")]), _c('option', {
    attrs: {
      "value": "Bank"
    }
  }, [_vm._v(_vm._s(_vm.$t("bank")))]), _c('option', {
    attrs: {
      "value": "Rakan Niaga"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("business-partner")) + " ")]), _c('option', {
    attrs: {
      "value": "Swasta"
    }
  }, [_vm._v(_vm._s(_vm.$t("private")))]), _c('option', {
    attrs: {
      "value": "Individu"
    }
  }, [_vm._v(_vm._s(_vm.$t("individual")))]), _c('option', {
    attrs: {
      "value": "Kerajaan"
    }
  }, [_vm._v(_vm._s(_vm.$t("government")))])])])]) : _vm._e(), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("trust.type")))]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.hibah_type_id,
      expression: "form.hibah_type_id"
    }],
    staticClass: "form-control",
    attrs: {
      "required": "",
      "id": "hibah_type_id"
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.form, "hibah_type_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("all")))]), _c('option', {
    attrs: {
      "value": "2"
    }
  }, [_vm._v(_vm._s(_vm.$t("corporate-trust-declaration")))]), _c('option', {
    attrs: {
      "value": "4"
    }
  }, [_vm._v(_vm._s(_vm.$t("cash-trust-declaration")))]), _c('option', {
    attrs: {
      "value": "6"
    }
  }, [_vm._v(_vm._s(_vm.$t("property-trust-declaration")))]), _c('option', {
    attrs: {
      "value": "8"
    }
  }, [_vm._v(_vm._s(_vm.$t("cash-trust")))]), _c('option', {
    attrs: {
      "value": "10"
    }
  }, [_vm._v(_vm._s(_vm.$t("property-trust")))]), _c('option', {
    attrs: {
      "value": "11"
    }
  }, [_vm._v(_vm._s(_vm.$t("business-trust")))]), _c('option', {
    attrs: {
      "value": "14"
    }
  }, [_vm._v(_vm._s(_vm.$t("estate-trust")))])])])]), _vm.userRole == 'Staff' ? _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("organization")))]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.organization_id,
      expression: "form.organization_id"
    }],
    staticClass: "form-control",
    attrs: {
      "id": "organization_id"
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.form, "organization_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, _vm._l(_vm.organization_array, function (role) {
    return _c('option', {
      key: role.id,
      domProps: {
        "value": role.id
      }
    }, [_vm._v(" " + _vm._s(role.name) + " ")]);
  }), 0)])]) : _vm._e(), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("status")))]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.status,
      expression: "form.status"
    }],
    staticClass: "form-control",
    attrs: {
      "required": "",
      "id": "status"
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.form, "status", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("all")))]), _c('option', {
    attrs: {
      "value": "0"
    }
  }, [_vm._v(_vm._s(_vm.$t("new2")))]), _c('option', {
    attrs: {
      "value": "1"
    }
  }, [_vm._v(_vm._s(_vm.$t("donor-update")))]), _c('option', {
    attrs: {
      "value": "2"
    }
  }, [_vm._v(_vm._s(_vm.$t("in-process")))]), _c('option', {
    attrs: {
      "value": "3"
    }
  }, [_vm._v(_vm._s(_vm.$t("accept-draft")))]), _c('option', {
    attrs: {
      "value": "4"
    }
  }, [_vm._v(_vm._s(_vm.$t("witness-in-process")))]), _c('option', {
    attrs: {
      "value": "5"
    }
  }, [_vm._v(_vm._s(_vm.$t("print-hibah")))])])])]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("mykad")))]), _c('the-mask', {
    staticClass: "form-control",
    attrs: {
      "id": "ic_number",
      "mask": ['######-##-####']
    },
    model: {
      value: _vm.form.ic_number,
      callback: function ($$v) {
        _vm.$set(_vm.form, "ic_number", $$v);
      },
      expression: "form.ic_number"
    }
  })], 1)]), _c('div', {
    staticClass: "col-lg-12 text-right"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('b-button', {
    staticClass: "btn btn-primary",
    on: {
      "click": _vm.searchQuery
    }
  }, [_vm._v(_vm._s(_vm.$t("search")))])], 1)])])])], 1)], 1)], 1), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "table-responsive bg-white shadow rounded mt-2"
  }, [_c('table', {
    staticClass: "table mb-0 table-center table-sm",
    staticStyle: {
      "font-size": "10pt"
    }
  }, [_c('thead', {
    staticClass: "bg-light"
  }, [_c('tr', [_c('th', [_vm._v(_vm._s(_vm.$t("index")))]), _c('th', [_vm._v(_vm._s(_vm.$t("hibah-no")))]), _c('th', [_vm._v(_vm._s(_vm.$t("type")))]), _c('th', [_vm._v(_vm._s(_vm.$t("grantors-name")))]), _c('th', [_vm._v(_vm._s(_vm.$t("old-ic")))]), _c('th', [_vm._v(_vm._s(_vm.$t("organization")))]), _c('th', [_vm._v(_vm._s(_vm.$t("org.nav2")))]), _c('th', [_vm._v(_vm._s(_vm.$t("action")))])])]), _vm.hibah_array != null ? _c('tbody', _vm._l(_vm.hibah_array, function (item, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(index))]), _c('td', [_vm._v(_vm._s(item.hibah_number ? item.hibah_number : "-"))]), _c('td', [_vm._v(_vm._s(item.hibah_type.name))]), _c('td', [_vm._v(" " + _vm._s(item.user_detail ? item.user_detail.name : "-") + " ")]), _c('td', [_vm._v(_vm._s(item.user ? item.user.ic_number : "-"))]), _c('td', [_vm._v(" " + _vm._s(item.organization ? item.organization.name : "-") + " ")]), _c('td'), _c('td', [_c('div', {
      staticClass: "btn-group-vertical",
      attrs: {
        "role": "group",
        "aria-label": "Vertical button group"
      }
    }, [_c('b-button', {
      staticClass: "btn btn-success btn-sm",
      attrs: {
        "block": ""
      },
      on: {
        "click": function ($event) {
          return _vm.kemaskiniHibah(item.id, item.hibah_type_id);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("update")) + " ")]), item.status == 5 ? _c('b-button', {
      staticClass: "btn-sm btn-danger",
      on: {
        "click": function ($event) {
          return _vm.generateCert(item.id);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("cert")) + " ")]) : _vm._e()], 1)])]);
  }), 0) : _vm._e()])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-8"
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": _vm.pagination.totalRows,
      "per-page": _vm.pagination.perPage,
      "aria-controls": "my-table"
    },
    on: {
      "change": _vm.pageClicks
    },
    model: {
      value: _vm.pagination.currentPage,
      callback: function ($$v) {
        _vm.$set(_vm.pagination, "currentPage", $$v);
      },
      expression: "pagination.currentPage"
    }
  })], 1), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form custom-form"
  }, [_c('div', {
    staticClass: "form-group mb-0"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.pagination.perPage,
      expression: "pagination.perPage"
    }],
    staticClass: "form-control custom-select",
    attrs: {
      "id": "Sortbylist-job"
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.pagination, "perPage", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }, function ($event) {
        return _vm.changePerPage();
      }]
    }
  }, [_c('option', {
    attrs: {
      "value": "10"
    }
  }, [_vm._v("10 " + _vm._s(_vm.$t("per-page")))]), _c('option', {
    attrs: {
      "value": "25"
    }
  }, [_vm._v("25 " + _vm._s(_vm.$t("per-page")))]), _c('option', {
    attrs: {
      "value": "50"
    }
  }, [_vm._v("50 " + _vm._s(_vm.$t("per-page")))])])])])])])])])])])]), _c('Footer'), _c('a', {
    directives: [{
      name: "scroll-to",
      rawName: "v-scroll-to",
      value: '#topnav',
      expression: "'#topnav'"
    }],
    staticClass: "btn btn-icon btn-soft-primary back-to-top",
    attrs: {
      "href": "javascript: void(0);",
      "id": "back-to-top"
    }
  }, [_c('arrow-up-icon', {
    staticClass: "icons"
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }